import React, { useEffect, useState } from 'react';
import Banner from '../../Design/Banner/Banner';
import "./Home.css";
import FilmCards from '../../Design/FilmCard/FilmCard';
import { ISearchFilm } from '../../Interfaces/FilmSearch';
import { useDragScroll } from '../../Components/Dragger';
import { Helmet } from 'react-helmet';
import { IFilm } from '../../Interfaces/Film';
import Rotes from "../../Utils/Routes.json";

function Home() {
  const [backgrounds, setBackgrounds] = useState({
    watched: "",
    new: "",
    trending: "",
  });

  const [filmData, setFilmData] = useState<ISearchFilm[]>([
    {
      sort: "new",
      page: 1,
      limit: 25,
      skeletonAmount: 9,
    },
    {
      sort: "views",
      page: 1,
      limit: 12,
      skeletonAmount: 9,
    },
    {
      sort: "watched",
      page: 1,
      limit: 25,
      skeletonAmount: 9,
    }
  ]);

  const [newRefDragger] = useDragScroll();

  const updateCardBG = (type: string, film: IFilm) => {
    setBackgrounds(prev => ({
      ...prev,
      [type]: film.film_banner,
    }));
  };

  useEffect(() => {
    const loadAdScript = (id: string, key: string, width: number, height: number) => {
      const adContainer = document.getElementById(id);
      if (!adContainer) {
        console.warn(`Ad container with id ${id} not found.`);
        return;
      }
  
      console.log(`Loading ad script for ${id}`);
      
      // Define ad options globally
      (window as any).atOptions = {
        key: key,
        format: 'iframe',
        height: height,
        width: width,
        params: {}
      };
  
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//chancesarmlessimpulse.com/${key}/invoke.js`;
      adContainer.appendChild(script);
    };
  
    // Load ad1 first, and delay ad2 slightly
    loadAdScript("ad1", "31b0ede2142ef4b12caeae1653920e5b", 728, 90);
    
    setTimeout(() => {
      loadAdScript("ad2", "ba3529dec34d34428f52c2a503bfe193", 468, 60);
    }, 200); // 200ms delay for ad2

  
    return () => {
      ["ad1", "ad2"].forEach(id => {
        const adContainer = document.getElementById(id);
        if (adContainer) adContainer.innerHTML = ""; // Clear ad scripts on unmount
      });
    };
  }, []);
  

  return (
    <>
      <Banner />
      <Helmet>
        <title>FxFilms - Watch Free Movies Online</title>
        <meta name="description" content="Welcome to FxFilms! Explore and watch a diverse range of free movies online. Enjoy streaming films from various genres with friends and family." />
        <meta name="keywords" content="films, FxFilms, free movies, watch movies, film streaming, cinema, movie nights" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="FxFilms - Watch Free Movies Online" />
        <meta property="og:description" content="Discover and enjoy a wide selection of free movies on FxFilms. Stream films of all genres and share the experience with others." />
        <meta property="og:url" content="https://fxfilms.net" />
      </Helmet>
      <div className='film-list'>
        <div className='film-classes' style={{ backgroundImage: `url(${Rotes.Domain + Rotes.imagesFilm + backgrounds.watched})` }}>
          <div className='header-title'>
            <p>New</p>
          </div>
          <div className='film-card-list' ref={newRefDragger}>
            <FilmCards filmData={filmData[2]} horizontal={true} continueLoading={true} play={true} speed={10000} onHover={(e: IFilm) => updateCardBG("watched", e)} />
          </div>
        </div>

        <div id="ad1" style={{ margin: "auto" }}></div>

        <div className='film-classes' style={{ backgroundImage: `url(${Rotes.Domain + Rotes.imagesFilm + backgrounds.new})` }}>
          <div className='header-title'>
            <p>New</p>
          </div>
          <div className='film-card-list' ref={newRefDragger}>
            <FilmCards filmData={filmData[0]} horizontal={true} continueLoading={true} play={true} speed={10000} onHover={(e: IFilm) => updateCardBG("new", e)} />
          </div>
        </div>

        <div id="ad2" style={{ margin: "auto" }}></div>

        <div className='film-classes' style={{ backgroundImage: `url(${Rotes.Domain + Rotes.imagesFilm + backgrounds.trending})`, height: "fit-content" }}>
          <div className='header-title'>
            <p>Trending</p>
          </div>
          <div className='film-card-list two-row'>
            <FilmCards filmData={filmData[1]} horizontal={false} continueLoading={true} play={true} speed={10000} onHover={(e: IFilm) => updateCardBG("trending", e)} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
