import React, { ReactElement } from 'react';
import CircularProgress from '../../Components/CircularProgress';

interface Props {
    like?: number | undefined; // Optional: number of likes
    dislike?: number | undefined; // Optional: number of dislikes
    size?: number; // Optional: size of the circular progress
    color?: string; // Optional: override color
    thickness?: number; // Optional: thickness of the circular progress
    sx?: React.CSSProperties; // Optional: custom styles (sx)
}

const FilmScoreDesign: React.FC<Props> = ({
    like = 0, // Default likes to 0 if undefined
    dislike = 0, // Default dislikes to 0 if undefined
    size = 48, // Default size
    thickness = 4, // Default thickness
    sx = {}, // Default styles
    color, // Optional override for color
}) => {
    // Calculate the score and its corresponding color
    const calculateScore = (
        likes: number,
        dislikes: number
    ): { score: number; color: string } => {
        const total = likes + dislikes;

        // If no likes or dislikes, set score to 0 and color to red
        if (total === 0) {
            return {
                score: 0,
                color: 'rgba(255, 0, 0, 0.5)',
            };
        }

        // Calculate score and round it to 1 decimal place
        const score = (likes / total) * 10;
        const scoreParsed = parseFloat(score.toFixed(1));

        // Calculate color based on the score
        const dynamicColor = calculateColor(scoreParsed);

        return {
            score: scoreParsed,
            color: dynamicColor,
        };
    };

    const calculateColor = (score: number): string => {
        // RGB color progression from red to green
        const red = score < 5 ? 255 : Math.round(255 - (score - 5) * 51);
        const green = score > 5 ? 255 : Math.round(score * 51);
        return `rgba(${red}, ${green}, 0, 0.5)`; // 50% opacity
    };

    // Get the score and its color
    const { score, color: dynamicColor } = calculateScore(like, dislike);

    // Use the provided color if available, otherwise use dynamicColor
    const progressColor = color || dynamicColor;

    // Render the CircularProgress component
    return (
        <CircularProgress
            score={score}
            color={progressColor}
            size={size}
            thickness={thickness}
            sx={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 2,
                ...sx, // Merge additional styles
            }}
        />
    );
};

export default FilmScoreDesign;
