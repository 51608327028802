export const convertUtcToLocalTime = (utcTimestamp: string | Date | number): Date => {
    // Parse the UTC timestamp and return a Date in the local timezone
    return new Date(utcTimestamp);
};

export const getTimeAgo = (utcTimestamp: string | Date | number | undefined): string => {
    if (!utcTimestamp) return "Invalid timestamp";

    // Convert the UTC timestamp to the user's local time
    const localTime = convertUtcToLocalTime(utcTimestamp);

    const now = new Date(); // Current local time
    const diff = now.getTime() - localTime.getTime(); // Difference in milliseconds

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return `${seconds} Second${seconds !== 1 ? "s" : ""} Ago`;
    if (minutes < 60) return `${minutes} Minute${minutes !== 1 ? "s" : ""} Ago`;
    if (hours < 24) return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    if (days < 30) return `${days} Day${days !== 1 ? "s" : ""} Ago`;
    if (months < 12) return `${months} Month${months !== 1 ? "s" : ""} Ago`;
    return `${years} Year${years !== 1 ? "s" : ""} Ago`;
};
